<script context="module" lang="ts">
  import _ from 'lodash'
  import { encode, decode } from 'js-base64'
  import axios from 'axios'
  import {
    user as userStore,
    repo as repoStore,
    site as siteStore,
  } from './stores'
  import firebase from './firebase/index.js'
  const functions = firebase.functions()

  if (window.location.hostname === 'localhost') {
    functions.useFunctionsEmulator('http://localhost:9000')
  }

  let user, repo, token, site

  userStore.subscribe((s) => {
    user = s
    token = s.token
  })
  repoStore.subscribe((s) => {
    if (s) {
      repo = `${s.owner}/${s.name}`
    }
  })
  siteStore.subscribe((s) => {
    site = s
  })

  export async function saveSite(data): Promise<boolean> {
    const json = JSON.stringify(data)
    const files = [{ path: 'primo.json', content: json }]
    const savedAsAdmin = await updateRepo({ files, message: 'Save site' })
    if (savedAsAdmin) return true
    const { data: savedAsEditor } = await functions.httpsCallable('saveSite')({
      repo,
      data: json,
    })
    if (!savedAsEditor) {
      alert(
        'For one reason or another, the site could not be saved.\nSorry about that.'
      )
    } else return true
  }

  export async function getCurrentUserData() {
    const { data } = await axios.get(`https://api.github.com/user`, {
      headers: {
        Authorization: 'token ' + token,
      },
    })
    return data
  }

  export async function buildSite({ site, files, message }, progress) {
    const builtAsAdmin = await updateRepo({ files, message }, progress)
    if (builtAsAdmin) return true
    const { data: buildAsEditor } = await functions.httpsCallable('buildSite')({
      site,
      repo,
      files,
      message,
      user,
    })
    if (!buildAsEditor) {
      alert(
        'For one reason or another, the site could not be published.\nSorry about that.'
      )
    } else return true
  }

  export async function updateRepo(
    { files, message, branch = 'main' },
    progress = (_) => {}
  ) {
    const newTree = files.map(({ path, content }) => ({
      path,
      mode: '100644',
      type: 'blob',
      content,
    }))

    try {
      // 1. Get parent commit
      const { data: parentCommit } = await axios.get(
        `https://api.github.com/repos/${repo}/git/ref/heads/${branch}`,
        {
          headers: {
            Authorization: 'token ' + token,
          },
        }
      )
      console.log('Get parent commit', parentCommit)

      progress(0.1)

      // 2. Get previous tree
      const { data: previousTree } = await axios.get(
        `https://api.github.com/repos/${repo}/git/trees/${parentCommit.object.sha}`,
        {
          headers: {
            Authorization: 'token ' + token,
          },
        }
      )
      console.log('Get the previous tree', previousTree)

      progress(0.3)

      // 3. Create a tree
      const { data: treeData } = await axios.post(
        `https://api.github.com/repos/${repo}/git/trees`,
        {
          tree: newTree,
          base_tree: previousTree.sha,
        },
        {
          headers: {
            Authorization: 'token ' + token,
          },
        }
      )
      console.log('2. Created a tree', treeData)

      progress(0.5)

      // 4. Create commit with the tree
      const treeHash = treeData.sha
      const { data: commitData } = await axios.post(
        `https://api.github.com/repos/${repo}/git/commits`,
        {
          message,
          tree: treeHash,
          committer: {
            name: 'primo',
            email: 'a@primo.press',
          },
          // TODO: reconnect
          // author: {
          //   name: user.githubUsername,
          //   email: user.email
          // },
          parents: [parentCommit.object.sha],
        },
        {
          headers: {
            Authorization: 'token ' + token,
          },
        }
      )
      console.log('3. Create a commit with the tree', commitData)

      progress(0.75)

      // 5. Update the branch with the commit
      const commitHash = commitData.sha
      const { data: updateRes } = await axios.patch(
        `https://api.github.com/repos/${repo}/git/refs/heads/${branch}`,
        {
          sha: commitHash,
          // force: true
        },
        {
          headers: {
            Authorization: 'token ' + token,
          },
        }
      )
      console.log(`4. Updated the ${branch} branch with the commit`, updateRes)
      return true
    } catch (e) {
      if (branch === 'main') {
        return await updateRepo({
          repo,
          token,
          files,
          message,
          branch: 'master',
        })
      } else {
        console.warn('Could not update repo as admin', e)
        return false
      }
    }
  }

  export async function saveFileToRepo({ file, content, message }) {
    try {
      const { data } = await axios.put(
        `https://api.github.com/repos/${repo}/contents/assets/${file}`,
        {
          message,
          content,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      return data
    } catch (e) {
      console.error(e)
      // const {data} = await functions.httpsCallable('uploadFile')({ repo, file, site, content, message })
      // if (!data) {
      //   console.error(e)
      //   return false
      // }
      // return data
    }
  }
  export async function createRepo({ name, visibility = 'public' }) {
    try {
      const { login: username } = await getCurrentUserData()
      const { data } = await axios.post(
        `https://api.github.com/user/repos`,
        {
          name: name,
          private: visibility === 'private',
          auto_init: true,
        },
        {
          headers: {
            Authorization: 'token ' + token,
          },
        }
      )
      return data
    } catch (e) {
      console.error(e)
      return false
    }
  }

  export async function fetchInitialSite(repo) {
    const { data } = await axios.get(
      `https://raw.githubusercontent.com/${repo}/main/primo.json`
    )
    return data
  }

  export async function fetchSite(
    repoToFetch = repo,
    isPrivate = false,
    file = 'primo.json',
    branch = 'main'
  ) {
    // console.log(`Fetching ${repoToFetch} at`, `${branch} / ${file}`)

    try {
      const { data: parentCommit } = await axios.get(
        `https://api.github.com/repos/${repoToFetch}/git/ref/heads/${branch}`,
        {
          headers: {
            Authorization: 'token ' + token,
          },
        }
      )
      const { data: previousTree } = await axios.get(
        `https://api.github.com/repos/${repoToFetch}/git/trees/${parentCommit.object.sha}`,
        {
          headers: {
            Authorization: 'token ' + token,
          },
        }
      )
      const saveFile = _.find(previousTree.tree, ['path', file])
      const fileHash = saveFile && saveFile.sha
      if (fileHash) {
        const { data } = await axios.get(
          `https://api.github.com/repos/${repoToFetch}/git/blobs/${fileHash}`,
          {
            headers: {
              Authorization: 'token ' + token,
            },
          }
        )
        return decodeContent(data.content)
      } else {
        throw Error
      }
    } catch (e) {
      const [newFile, newBranch] = {
        'primo.json--main': ['primo.json', 'master'],
        'primo.json--master': ['site-primo.json', 'main'],
        'site-primo.json--main': ['site-primo.json', 'master'],
      }[`${file}--${branch}`] || [null]

      if (newFile) {
        return await fetchSite(repoToFetch, isPrivate, newFile, newBranch)
      } else if (isPrivate) {
        try {
          const shouldAuth = window.confirm(
            `This repo (${repoToFetch}) appears to be private. You'll need to grant additional permissions so primo can access it. You could also set it to public.`
          )
          // if (shouldAuth) signUpWithGithub(true, true) TODO
          // else window.alert(`The repository (${repoToFetch}) will need to be public to work in primo`)
        } catch (e) {
          alert(`${repoToFetch} doesn't appear to exist.`)
        }
      }
    }

    function decodeContent(content) {
      const decoded = decode(content)
      return JSON.parse(decoded)
    }
  }

  export async function fetchDataFromRepo(repoUrl) {
    try {
      const { data } = await axios.get(repoUrl)
      return data
    } catch (e) {
      return null
    }
  }
</script>
