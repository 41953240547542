import {writable, get} from 'svelte/store'
import {users,sites} from '../supabase/db'
import user from '../stores/user'

const store = writable([]);

export default {
  fetch: async () => {
    const uid = get(user)?.uid
    if (uid) {
      const {websites} = await users.get(uid, `websites`)
      const allSites = websites ? await Promise.all( // for some reason, invited sites can't get pulled in the normal query
        websites.map(id => sites.get({id, query: `id, preview, name, url, repo (url, isPublic), owner (id, username), collaborators, collaborator_data`}))
      ) : []
      store.set(allSites.filter(Boolean))
    }
  },
  set: store.set,
  subscribe: store.subscribe
}