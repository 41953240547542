'use strict'

import { writable, get } from 'svelte/store'
import Mousetrap from 'mousetrap'

const initialState = {
  id: null,
  visible: false,
  componentProps: {},
  header: {
    title: '',
    icon: null
  },
  variants: '',
  disableClose: false,
  showSwitch: false
}

const store = writable(initialState)

const modal_startup = () => {
  Mousetrap.bind('backspace', (e) => {
    e.preventDefault()
  })
}
const modal_cleanup = () => {
  Mousetrap.unbind('backspace')
}

// Defined under @modal and App.svelte
const modalTypes = {
  'INVITATION': {
    variants: 'max-w-md'
  },
  'USER_SETTINGS': {
    variants: 'max-w-md'
  },
  'SITE_CREATION': {
    variants: 'max-w-md'
  },
  'AUTH': {
    variants: 'max-w-md'
  },
  'COLLABORATORS': {
    variants: 'max-w-md'
  }
}

export default {
  show: (type, props = {}, modalOptions = {}) => {
    const typeToShow = getModalType(type, props, modalOptions)
    modal_startup()
    store.update(s => ({
      id: type,
      componentProps: props,
      ...modalOptions,
      visible: true
    }))
  },
  hide: () => {
    modal_cleanup()
    store.update(s => initialState)
  },
  create: (modal) => {
    if (Array.isArray(modal)) {
      modal.forEach(createModal)
    } else if (typeof modal === 'object') {
      createModal(modal)
    } else {
      console.error('Could not register modal an array or object')
    }

    function createModal(modal) {
      const { id, component, componentProps, options = {} } = modal
      modalTypes[id] = {
        component,
        header: options.header,
        variants: options.width ? `max-w-${options.width}` : '',
        ...options,
        componentProps
      }
    }
  },
  subscribe: store.subscribe
}

function getModalType(type, props, modalOptions) {
  return {
    componentProps: props,
    ...modalTypes[type],
    ...modalOptions
  } || console.error('Invalid modal type:', type)
}
