<script>
  import Spinner from './misc/Spinner.svelte'
  import { downloadPagePreview } from '../supabase/storage'

  export let site = null
  export let preview = null
  export let variants = 'h-full'

  let container
  let scale
  let iframe
  let iframeLoaded

  function resizePreview() {
    const { clientWidth: parentWidth } = container
    const { clientWidth: childWidth } = iframe
    scale = parentWidth / childWidth
  }

  async function getPreview() {
    const { data } = await downloadPagePreview({
      owner: site.owner.id,
      id: site.id,
    })
    preview = data ? await data.text() : ''
  }
  if (!preview) {
    getPreview()
  }
</script>

<div class="iframe-root {variants}">
  <div bind:this={container} class="iframe-container">
    {#if !iframeLoaded}
      <div class="h-full flex justify-center items-center">
        <Spinner />
      </div>
    {/if}
    {#if preview}
      <iframe
        tab-index="-1"
        bind:this={iframe}
        style="transform: scale({scale})"
        class:fadein={iframeLoaded}
        title="page preview"
        srcdoc={preview}
        on:load={() => {
          resizePreview()
          iframeLoaded = true
        }}
      />
    {/if}
  </div>
</div>

<style>
  .iframe-root {
    pointer-events: none;
    overflow: hidden;
    @apply relative;
  }
  .iframe-container {
    @apply absolute bottom-0 top-0 left-0 right-0 z-10 bg-transparent opacity-100 transition-all duration-200 w-full h-full text-xs overflow-hidden;
    overflow-wrap: break-word;
  }
  iframe {
    @apply pointer-events-none opacity-0 transition-opacity duration-200 bg-white;
    width: 100vw;
    will-change: opacity;
    transform-origin: top left;
    height: 1000vh;
  }
  .fadein {
    @apply opacity-100;
  }
</style>
