<script lang="ts">
  import { onMount } from 'svelte'
  import { cloneDeep } from 'lodash'
  import { sites as supabaseSites, users } from '../supabase/db'
  import { fade } from 'svelte/transition'
  import { router } from 'tinro'
  import SignInNav from './Home/SignInNav.svelte'
  import SiteFooter from './Home/SiteFooter.svelte'
  import SiteThumbnail from '../@components/SiteThumbnail.svelte'
  import Spinner from '../@components/misc/Spinner.svelte'
  import { modal, user, sites } from '../stores'

  async function deleteSiteItem(siteID: string): Promise<any> {
    deleting = siteID
    const newSites = $sites.filter((site) => site.id !== siteID)
    $sites = newSites
    const newSitesID = newSites.map((site) => site.id)
    await Promise.all([
      users.update($user.uid, { websites: newSitesID }),
      supabaseSites.delete(siteID),
    ])
    deleting = null
  }

  function beginInvitation(site): void {
    modal.show('INVITATION', { site })
  }

  let loading
  let deleting
  function createSite(): void {
    modal.show('SITE_CREATION', {
      onSuccess: async (url) => {
        router.goto(`${$user.username}/${url}`)
        modal.hide()
      },
    })
  }

  let timeout
  let siteBeingDeleted
  function startDeleting(siteID) {
    siteBeingDeleted = siteID
    timeout = setTimeout(() => {
      deleteSiteItem(siteID)
      siteBeingDeleted = null
    }, 3000)
  }

  function stopDelete() {
    siteBeingDeleted = null
    clearTimeout(timeout)
  }

  let siteBeingEdited
  let siteNewName = ''
  function editSite(site) {
    siteBeingEdited = cloneDeep(site)
    siteNewName = site.name
  }
  function saveSiteDetails(props) {
    $sites = $sites.map((site) =>
      site.id !== siteBeingEdited.id
        ? site
        : {
            ...site,
            ...props,
          }
    )
    supabaseSites.update(siteBeingEdited.id, props)
    siteBeingEdited = null
  }
  function showCollaborators(site) {
    modal.show('COLLABORATORS', { site })
  }
</script>

<div class="bg-black py-6 px-4 min-h-screen">
  <div class="container max-w-2xl py-3 bg-gray-900 rounded">
    <SignInNav />
    <main class="pt-4 px-4 mb-4" in:fade>
      <div class="sites grid md:grid-cols-2 gap-4" xyz="fade stagger">
        {#each $sites as site, i (site.id)}
          <div
            class="xyz-in rounded overflow-hidden text-lg shadow-md flex flex-col justify-between transition-colors duration-200"
            in:fade={{ duration: 100 }}
          >
            <a
              href="/{site.owner.id === $user.uid
                ? $user.username
                : site.owner.username}/{site.url}"
              class="hover:opacity-75 transition-opacity duration-100"
            >
              <SiteThumbnail {site} variants="aspect-w-16 aspect-h-12" />
            </a>
            <div class="pb-8 pt-6 px-5 space-y-4 relative">
              <div class="flex flex-col space-y-4 text-gray-100">
                <div class="flex flex-col items-start">
                  {#if siteBeingEdited && siteBeingEdited.id === site.id}
                    <form
                      class="flex items-center pb-1"
                      on:submit|preventDefault={() =>
                        saveSiteDetails({ name: siteNewName })}
                    >
                      <input
                        on:blur={() => (siteBeingEdited = null)}
                        autofocus
                        class="reset-input text-left text-lg font-semibold focus:outline-none"
                        type="text"
                        bind:value={siteNewName}
                      />
                    </form>
                  {:else}
                    <div class="flex items-center">
                      <a
                        class="text-left font-semibold pb-1"
                        href="{$user.username}/{site.url}">{site.name}</a
                      >
                      {#if site.owner.id === $user.uid}
                        <button
                          on:click={() => editSite(site)}
                          class="rounded p-2 hover:text-primored focus:outline-none focus:ring ring-primored transiticon-colors duration-100"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-full w-3"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      {/if}
                    </div>
                  {/if}
                  <span class="text-xs text-gray-400">
                    <span
                      >{site.owner.id !== $user.uid
                        ? site.owner.username
                        : ''}/{site.url}</span
                    >
                  </span>
                </div>
                {#if site.owner.id === $user.uid}
                  <div class="flex items-start text-gray-300 mt-2 space-x-2">
                    <div class="button-group">
                      {#if site.collaborators}
                        <button on:click={() => showCollaborators(site)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-4 h-full"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
                            />
                          </svg>
                        </button>
                      {/if}
                      <button on:click={() => beginInvitation(site)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-full w-3"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                          />
                        </svg>
                        <span>Invite</span>
                      </button>
                    </div>
                    {#if siteBeingDeleted === site.id}
                      <button
                        on:click={stopDelete}
                        class="small-button text-gray-100 bg-gray-800 flex font-semibold"
                      >
                        <span class="sr-only">Stop deleting!</span>
                        <Spinner variants="h-4 h-4 mr-2" />
                        Stop deleting!
                      </button>
                    {:else if !siteBeingDeleted}
                      <button
                        on:click={() => startDeleting(site.id)}
                        class="small-button text-gray-100 bg-gray-800 "
                      >
                        <span class="sr-only">Delete</span>
                        <svg
                          class="w-4 h-4"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          ><path
                            fill-rule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          /></svg
                        >
                      </button>
                    {/if}
                  </div>
                {/if}
              </div>
            </div>
          </div>
        {/each}
        {#if $sites.length <= 30}
          <button
            class:col-span-2={$sites.length === 0}
            class="py-12 flex flex-col items-center justify-center w-full ring-primored bg-black ring-2 font-semibold text-gray-200 rounded hover:bg-primored transition-colors duration-100"
            on:click={createSite}
          >
            {#if loading}
              <Spinner />
            {:else}
              <svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                ><path
                  fill-rule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clip-rule="evenodd"
                /></svg
              >
            {/if}
            create a site
          </button>
        {/if}
      </div>
    </main>
    <SiteFooter variants="my-3 text-xs" />
  </div>
</div>

<style>
  .button-group {
    display: flex;
    overflow: hidden;
    @apply rounded;

    button {
      @apply text-gray-100 bg-gray-700 font-semibold flex items-center space-x-1;
      @apply text-xs hover:bg-primored py-1 px-2 transition-colors duration-100 focus:outline-none focus:ring focus:ring-primored;
      &:not(:last-child) {
        @apply border-r border-gray-600;
      }
    }
  }
  .small-button {
    @apply text-xs rounded hover:bg-primored py-1 px-2 transition-colors duration-100 focus:outline-none focus:ring focus:ring-primored;

    &.is-primary {
      @apply text-gray-100 bg-gray-700 font-semibold flex items-center space-x-1;
    }
  }

  .reset-input {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    @apply bg-transparent border-0 p-0 m-0;
  }
</style>
