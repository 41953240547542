<script>
  import Spinner from '../../@components/misc/Spinner.svelte'
  import {createEventDispatcher} from 'svelte'
  const dispatch = createEventDispatcher();

  export let label = ''
  export let id = null
  export let variants = 'w-full font-medium'
  export let type = 'button'
  export let disabled = false
  export let loading = false
</script>

<button on:click {id} class="bg-black hover:bg-primored  rounded ring-2 ring-primored py-1 px-4 {variants}" {disabled} {...$$restProps} on:click={(e) => dispatch('click', e)} {type}>
  {#if loading}
    <Spinner variants="absolute top-0 bottom-0 h-full p-2" />
  {/if}
  <div class:invisible={loading}>
    <slot>{label}</slot>
  </div>
</button>

<style>
  button {
    @apply transition-all duration-100 relative inline-flex items-center justify-center;

    &[disabled] {
      @apply opacity-50 pointer-events-none;
    }
  }
</style>