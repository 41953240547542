<script>
  import Primo from '@primo-app/primo'
  import { createNewSite } from '@primo-app/primo'
  import { sites } from '../supabase/db'
  import { downloadSiteData } from '../supabase/storage'
  import { router } from 'tinro'

  let role = 'developer'

  let activeSite = createNewSite({ name: 'Default Site' })
  $: fetchSite($router.path)
  async function fetchSite(fullPath) {
    let saved = localStorage.getItem('try-primo')
    if (saved) {
      activeSite = JSON.parse(saved)
    } else {
      const storageRes = await downloadSiteData({
        owner: '7ff42f0c-6a06-4ff4-b23e-695e11075350',
        id: '2b303a42-8ea5-46f8-b758-da602152cd3e',
      })
      const json = await storageRes.data.text()
      activeSite = JSON.parse(json)
    }
  }

  async function saveData(data) {
    const json = JSON.stringify(data)
    const blob = new Blob([json]).size
    console.log('Saving', blob / 1000, data)
    localStorage.setItem('try-primo', json)
  }

  let libraries = []
  downloadSiteData({
    owner: '7ff42f0c-6a06-4ff4-b23e-695e11075350',
    id: 'bffe679e-5c85-4f0e-ad05-e5c578dad379',
  }).then(async (res) => {
    const json = await res.data.text()
    const data = JSON.parse(json)
    libraries = [
      {
        label: 'Public Library',
        icon: 'users',
        components: data.symbols,
      },
    ]
  })
</script>

<Primo
  data={activeSite}
  {role}
  {libraries}
  on:save={async ({ detail: data }) => saveData(data)}
/>
