import { firebase } from '@firebase/app'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyBVh2V0MgMfHY8O_-etZBdtQ14YWdVLxBo',
  authDomain: 'auth.primo.af',
  databaseURL: 'https://primo-d4041.firebaseio.com',
  projectId: 'primo-d4041',
  storageBucket: 'primo-d4041.appspot.com',
  messagingSenderId: '420475988953',
  appId: '1:420475988953:web:84d3a91901440ac3ab47c8',
  measurementId: 'G-C6RSLQN5W4',
}

const app = firebase.initializeApp(firebaseConfig)

export default app

