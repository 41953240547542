<script>
  import { fade } from 'svelte/transition'
  import { router } from 'tinro'
  import { Spinner } from './@components/misc'
  import { user, modal } from './stores'
  import Authentication from './@modal/Authentication.svelte'
  import Logo from './@svg/Logo.svelte'
  import { auth } from './supabase/auth'

  export let passwordResetToken = null

  let password = ''
  let resetPasswordError = null
  let success = false
  async function finishResettingPassword() {
    const { error, data } = await auth.api.updateUser(passwordResetToken, {
      password,
    })
    console.log({ error, data })
    if (data) {
      success = true
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } else {
      resetPasswordError = 'Could not reset password'
    }
  }

</script>

<div
  in:fade
  class="min-h-screen flex flex-col items-center justify-between bg-black py-12 px-4 sm:px-6 lg:px-8"
>
  <div class="max-w-sm w-full flex-1 flex flex-col justify-center items-center">
    {#if passwordResetToken}
      <main>
        <div class="logo text-center">
          <div class="p-4 pt-0 flex justify-center">
            <a
              href="https://primo.af"
              target="blank"
              data-xyz="fade"
              class="w-64 max-w-md py-0 px-8 opacity-100 transition-opacity duration-200 hover:opacity-75"
            >
              <Logo />
            </a>
          </div>
        </div>
        {#if success}
          <span
            class="block p-4 bg-yellow-200 rounded font-semibold mb-4"
            in:fade
          >
            Password reset successfully, refreshing page
          </span>
        {:else if resetPasswordError}
          <span class="block p-4 bg-red-200 rounded font-semibold mb-4" in:fade>
            {@html resetPasswordError}
          </span>
        {/if}
        <form
          on:submit|preventDefault={finishResettingPassword}
          class="space-y-2 mb-4"
        >
          <div class="flex flex-col">
            <label for="password" class="text-white pb-1 font-semibold"
              >Enter your new password:</label
            >
            <input
              autocomplete="true"
              id="password"
              bind:value={password}
              name="password"
              type="password"
              required
              class="rounded bg-white appearance-none mb-2 relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b focus:outline-none focus:ring-primored focus:border-primored focus:z-10 sm:text-sm"
              placeholder="Password"
            />
            <button type="submit" class="primary-button"
              >Finish resetting</button
            >
          </div>
        </form>
      </main>
    {:else}
      <Authentication
        onSignIn={() => {
          $user.signedIn = true
          router.goto('/dashboard')
          modal.hide()
        }}
      />
    {/if}
    <div class="block text-cenleftter text-xs text-gray-300 my-2">
      By signing into primo, you agree to our <br />
      <a
        href="https://www.iubenda.com/terms-and-conditions/39882203"
        class="underline hover:text-gray-300 focus:outline-none focus:underline transition ease-in-out duration-150"
      >
        Terms of Service
      </a>
      and
      <a
        href="https://www.iubenda.com/privacy-policy/39882203"
        class="underline hover:text-gray-300 focus:outline-none focus:underline transition ease-in-out duration-150"
      >
        Privacy Policy
      </a>
    </div>
  </div>
  <div class="w-full">
    <div class="container flex flex-col md:flex-row justify-between">
      <p class="text-left text-gray-100 mb-2 text-sm">
        <strong>primo</strong> public beta
      </p>
      <ul
        class="flex flex-row justify-start md:justify-center items-center text-gray-500 font-semibold"
      >
        <li class="mr-8 hover:text-primored transition-colors duration-200">
          <a href="https://discord.gg/vzSFTS9">Community</a>
        </li>
        <li class="mr-8 hover:text-primored transition-colors duration-200">
          <a
            href="https://www.iubenda.com/terms-and-conditions/39882203"
            class="iubenda-nostyle no-brand iubenda-embed"
            title="Terms and Conditions ">Terms</a
          >
        </li>
        <li class="hover:text-primored transition-colors duration-200">
          <a
            href="https://www.iubenda.com/privacy-policy/39882203"
            class="iubenda-nostyle no-brand iubenda-embed"
            title="Privacy Policy ">Privacy</a
          >
        </li>
      </ul>
    </div>
  </div>
</div>

<style>
  .primary-button {
    @apply group bg-gray-800 relative w-full flex items-center justify-center py-2 px-4 border border-transparent text-sm font-semibold rounded text-white hover:text-white hover:bg-primored focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primored transition-colors duration-100;
  }
  button[disabled] {
    @apply opacity-75 pointer-events-none;
  }

</style>
