import supabase from './core'


export async function updatePagePreview({ path, preview }) {
  const blob = new Blob([preview], {type: 'text/plain'});
  const file = new File([blob], "filename");
  let res = await supabase
    .storage
    .from('sites')
    .update(path, file)

  if (!res.data) {
    res = await supabase
      .storage
      .from('sites')
      .upload(path, file)
  }
  return res
}

export async function downloadPagePreview({ owner, id }) {
  return await supabase
    .storage
    .from('sites')
    .download(`${owner}/${id}/preview.html`)
}

export async function uploadPagePreview({ path, preview }) {
  const blob = new Blob([preview], {type: 'text/plain'});
  const file = new File([blob], "filename");
  let res = await supabase
    .storage
    .from('sites')
    .upload(path, file)

  return res
}

export async function uploadSiteData({ owner, id, data }) {
  const json = JSON.stringify(data)
  const blob = new Blob([json], {type: 'text/plain'});
  const file = new File([blob], "filename");
  const res = await supabase
    .storage
    .from('sites')
    .upload(`${owner}/${id}/site.json`, file)
  return res
}

export async function updateSiteData({ owner, id, data }) {
  const json = JSON.stringify(data)
  const blob = new Blob([json], {type: 'text/plain'});
  const file = new File([blob], "filename");
  const res = await supabase
    .storage
    .from('sites')
    .update(`${owner}/${id}/site.json`, file)
  return res
}

export async function downloadSiteData({ owner, id }) {
  const res = await supabase
    .storage
    .from('sites')
    .download(`${owner}/${id}/site.json`)

  return res
}