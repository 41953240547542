import axios from 'axios'
import PromiseWorker from 'promise-worker';
import {get} from 'svelte/store'
import {site,activePage} from '@primo-app/primo'

const postCSSWorker = new Worker('/processors/postcss.worker.js');
const cssPromiseWorker = new PromiseWorker(postCSSWorker);

const handlebarsWorker = new Worker('/processors/handlebars.worker.js');
const htmlPromiseWorker = new PromiseWorker(handlebarsWorker);

export async function html(code, data) {
  const dataWithPrimoData = {
    ...data,
    page: get(activePage), 
    site: get(site)
  }

  let res = await htmlPromiseWorker.postMessage({
    code,
    data: dataWithPrimoData
  })

  if (res.error) {
    res = `<pre class="flex justify-start p-8 items-center bg-red-100 text-red-900 h-screen font-mono text-xs lg:text-sm xl:text-md">${res.error}</pre>`
  }

  return res
}


export async function css(raw, options = {}) {

  if (!options.tailwind) {
    return ''
  }

  let twObject = new Function(`const require = (id) => '>>' + id + '<<'; return ${options.tailwind}`)()

  // console.log({twObject})

  if (options.html) {
    twObject = {
      ...twObject,
      purge: false
      // TODO: figure out how to avoid 'o.a. is not a constructor' bug
      // purge: {
      //   enabled: true,
      //   content: [
      //     {
      //       raw: options.html,
      //       extension: 'html',
      //     },
      //   ],
      // }
    }
  }

  const asString = JSON.stringify(twObject)

  // console.log({asString})
  const withPlugins = asString.replace(`>>@tailwindcss/ui<<`,`require('@tailwindcss/ui')`) // regex instead
  const config = `module.exports = ${withPlugins}`

  const res = await cssPromiseWorker.postMessage({
    css: raw,
    config
  })

  return res
}