<script>
  import Primo, {
    modal,
    createNewSite,
    site,
    savedSite,
    stores,
  } from '@primo-app/primo'
  import { find } from 'lodash'
  import { signOut } from '../supabase/auth'
  import { sites } from '../supabase/db'
  import {
    uploadSiteData,
    downloadSiteData,
    updateSiteData,
    updatePagePreview,
  } from '../supabase/storage'
  import supabase from '../supabase/core'
  import { user, currentSite, repo } from '../stores'
  import { path } from '../stores/misc'

  import FeedbackForm from '../@components/FeedbackForm.svelte'
  import { router } from 'tinro'
  import { buildPagePreview } from '@primo-app/primo/src/stores/helpers'

  let role = 'developer'

  if (!$user.signedIn) {
    modal.show('AUTH', {
      onSignIn: () => {
        fetchSite($router.path)
      },
    })
  }

  let activeSite = createNewSite({ name: 'Default Site' })
  $: $user.signedIn && fetchSite($router.path)
  async function fetchSite(fullPath) {
    if (fullPath.includes('dashboard') || fullPath.includes($currentSite))
      return
    const [username, siteID] = fullPath.slice(1).split('/')
    $path = `${username}/${siteID}`

    const res = await sites.get({ path: $path })
    console.log({ res })

    if (res) {
      const { id, owner, repo: repository, data, collaborator_data } = res
      const storageRes = await downloadSiteData({ owner: owner.id, id })
      saveFile = { owner: owner.id, id }

      let site
      if (storageRes.data) {
        const json = await storageRes.data.text()
        site = JSON.parse(json)
      } else {
        site = data
      }
      activeSite = site || createNewSite({ name: site.name })

      $repo = repository
      $currentSite = id
      stores.$unsaved = false

      // set collaborator role
      if (owner.id !== $user.uid) {
        console.log(collaborator_data)
        const collaborator = find(collaborator_data, ['uid', $user.uid])
        role = collaborator.role
        console.log({ role })
      }
    }
  }

  let saveFile = {}
  async function saveData(data) {
    saving = true
    await transferSiteToStorage({
      owner: saveFile.owner,
      id: saveFile.id,
      data,
    })
    saving = false
    const homepage = data.pages.filter((p) => p.id === 'index')[0]
    const preview = await buildPagePreview({ page: homepage, site: data })
    await updatePagePreview({
      path: `${saveFile.owner}/${saveFile.id}/preview.html`,
      preview,
    })
  }

  async function transferSiteToStorage(args) {
    const { data } = await updateSiteData(args)
    if (!data) {
      const uploaded = await uploadSiteData(args)
      if (!uploaded.data) return false
    }
    return true
  }

  let saving = false

  let libraries = []
  downloadSiteData({
    owner: '7ff42f0c-6a06-4ff4-b23e-695e11075350',
    id: 'bffe679e-5c85-4f0e-ad05-e5c578dad379',
  }).then(async (res) => {
    const json = await res.data.text()
    const data = JSON.parse(json)
    libraries = [
      {
        label: 'Public Library',
        icon: 'users',
        components: data.symbols,
      },
    ]
  })
</script>

<Primo
  data={activeSite}
  {libraries}
  {role}
  {saving}
  on:save={async ({ detail: data }) => saveData(data)}
  on:signOut={signOut}
/>

<!-- {#if !stores.$onMobile}
{/if} -->
<!-- <FeedbackForm /> -->
