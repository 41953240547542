<script lang="ts">
  import { fade } from 'svelte/transition'
  import user from '../stores/user'
  import { createUniqueID } from '@primo-app/primo/src/utilities'
  import PrimaryButton from '../@components/buttons/PrimaryButton.svelte'
  import { sites } from '../supabase/db'

  export let site

  let role: string = 'DEV'

  let collaborators = site.collaborators || []
  let password = parsePassword(site.password)
  $: if (password) {
    showingLink = true
    createLink()
  }

  function parsePassword(rawPass) {
    if (rawPass) {
      return rawPass.replace(/DEV|CONTENT/g, ``).slice(1)
    } else {
      return null
    }
  }

  let loading: boolean = false

  let link
  let copied

  let showingLink = false
  function showLink() {
    showingLink = true
    password = createUniqueID(15)
    createLink()
    savePass(`${role}-${password}`)
  }

  function createLink() {
    link = `https://its.primo.af/${$user.username}/${site.url}?pass=${password}`
  }

  async function savePass(pass) {
    await sites.update(site.id, { password: pass })
  }

  async function copyLink() {
    if (!navigator.clipboard) {
      alert(
        'Unable to copy Symbol because your browser does not support copying'
      )
      return
    }
    copied = true
    await navigator.clipboard.writeText(link)
  }

  function parseDate(date) {
    return new Date(date).toLocaleDateString('en-US')
  }
</script>

<div class=" text-gray-100 bg-gray-900 p-8 ring-2 ring-primored rounded">
  <h1 class="mb-2 text-xl font-bold">Invite a collaborator to {site.name}</h1>
  <div class="space-y-2">
    {#if !loading}
      <div>
        <div class="my-4">
          <span class="text-base font-semibold pb-1">Collaborator role</span>
          <div class="toggle">
            <button
              class:selected={role === 'DEV'}
              type="button"
              on:click={() => (role = 'DEV')}>Developer</button
            >
            <button
              class:selected={role === 'CONTENT'}
              type="button"
              on:click={() => (role = 'CONTENT')}>Content Editor</button
            >
          </div>
          <div class="flex mt-4">
            {#if role === 'CONTENT'}
              <p>
                Content Editors only have access to the site's content, so
                they'll never accidentally run into its code and break
                something.
              </p>
            {:else if role === 'DEV'}
              <p>
                Developers have full access to the site's code and content. They
                can create new components and connect fields for Content Editors
                to easily edit.
              </p>
            {/if}
          </div>
        </div>
        <hr class="border-gray-800 my-6" />
        <div class="flex justify-center mt-8">
          {#if showingLink}
            <div class="space-y-4">
              <div class="text-xl">
                Share this <strong>secret invitation link</strong> with someone so
                they can start collaborating on your site
              </div>
              <div class="flex items-center space-x-4">
                <button
                  class:selected={copied}
                  on:click={copyLink}
                  class="flex justify-center items-center max-w-md ring-2 ring-primored hover:bg-primored transition-colors duration-100 py-2 px-3 rounded focus:outline-none focus:bg-codeblack focus:ring-0"
                >
                  {#if copied}
                    <span class="sr-only">Copy link</span>
                    <svg
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      ><path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      /></svg
                    >
                  {:else}
                    <span class="sr-only">Link copied</span>
                    <svg
                      in:fade
                      class="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      ><path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z" /><path
                        d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"
                      /></svg
                    >
                  {/if}
                </button>
                <span class="">{link}</span>
              </div>
            </div>
          {:else}
            <PrimaryButton on:click={showLink} label="Create Link" />
          {/if}
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  .toggle {
    @apply flex mt-1 rounded-sm overflow-hidden;
  }
  .toggle > button {
    @apply flex-1 bg-gray-100 text-gray-700 py-2 font-medium transition-colors duration-200;
  }
  button.selected {
    @apply bg-gray-800 text-gray-100 transition-colors duration-200 outline-none cursor-default ring-0;
  }
  button[disabled] {
    @apply bg-blue-300 cursor-not-allowed transition-colors duration-200;
  }
</style>
