<script>
  import { onMount } from 'svelte'
  import { fade } from 'svelte/transition'
  import auth from '../supabase/auth'
  // import {acceptInvitation} from '../supabase/db'
  import { acceptSiteInvitation } from '../supabase/middleware'
  import { addSiteToUser } from '../supabase/helpers'
  import { createUser } from '../supabase/helpers'
  import { stores } from '@primo-app/primo'
  import { router } from 'tinro'

  import { Spinner } from '../@components/misc'
  import Logo from '../@svg/Logo.svelte'
  import { modal, user } from '../stores'

  export let onSignIn = () => {}

  let loading

  let collabPass = $router.query.pass
  $: collabPass &&
    (headerMessage = `You've been invited to collaborate on this site. Sign in or create an account to continue.`)

  async function handleInvitation() {
    loading = true
    const success = await joinSite(collabPass)
    if (success) {
      loginMessage = `Successfully logged in, adding you as a collaborator`
      window.history.replaceState(null, null, window.location.pathname) // remove query manually because router.location.query.clear() isn't working
      setTimeout(() => {
        collabPass = null
      }, 2000)
    } else {
      loginMessage = `That link appears to be expired or invalid. Try asking the site owner to send you a fresh one.`
    }
  }

  async function joinSite(password) {
    const [owner, url] = $router.path.substring(1).split('/')
    console.log({ owner, url })
    const res = await acceptSiteInvitation({
      password,
      uid: $user.uid,
      owner,
      url,
    })

    if (res) {
      const { role, siteID } = res
      stores.$userRole = role === 'DEV' ? 'developer' : 'content'
      await addSiteToUser($user.uid, siteID)
      return true
    } else return false
  }

  let collaboratorPass

  let loginMessage
  let headerMessage
  let loginError
  let loadingEmail,
    loadingProvider = false

  let email,
    password = ''
  $: signInWithMagicLink = !signingUp && email && !password
  $: disabled = !signInWithMagicLink && (!email || password.length <= 3)

  async function signUp() {
    // window.plausible(`Signing up with Email`, { props: { email } })
    loadingEmail = true
    loginError = null
    loginMessage = null
    const res = await createUser({ email, password })
    if (res.error) {
      loginError = res.error
    } else {
      loginMessage = `A confirmation email has been sent to <strong>${email}.</strong><br>It contains the next clue.`
    }
    loadingEmail = false
  }

  async function signUpWithProvider() {
    // window.plausible(`Signing up with Github`)
    loadingProvider = true
    auth.signInWithGithub({ redirectTo: window.location.href })
  }

  async function signIn() {
    // window.plausible(`Signing In`, { props: { email } })
    loginError = ''
    loadingEmail = true

    if (!$user.signedIn) {
      const { error, user: res } = await auth.signIn({ email, password })
      if (error) {
        loginError = error.message
      } else if (signInWithMagicLink) {
        loginMessage = `A magic link has been sent to <strong>${email}</strong>.<br>When you click on it, you'll be logged into primo.`
      } else if (res) {
        user.update((u) => ({ ...u, ...res }))
      }
    }

    loadingEmail = false
  }

  async function resetPassword() {
    const { error } = await auth.resetPassword(email)
    if (error) {
      loginError = error.message
    } else {
      loginMessage = `A link has been sent to <strong>${email}</strong> with instructions to reset your password`
    }
  }

  $: if ($user.signedIn && collabPass) {
    handleInvitation()
  } else if ($user.signedIn) {
    onSignIn()
  }

  let mounted
  onMount(() => {
    mounted = true
  })

  let signingUp = false
</script>

{#key signingUp}
  <main>
    <div class="logo text-center">
      <div class="p-4 pt-0 flex justify-center">
        <a
          href="https://primo.af"
          target="blank"
          xyz="fade"
          class="w-64 max-w-md py-0 px-8 opacity-100 transition-opacity duration-200 hover:opacity-75"
        >
          <Logo />
        </a>
      </div>
    </div>
    {#if loginMessage}
      <div
        in:fade
        class="rounded bg-gray-800 text-gray-100 p-6 mb-6 text-sm text-center font-semibold"
      >
        <div class="flex justify-center mb-2">
          <Spinner />
        </div>
        {@html loginMessage}
      </div>
    {:else if loading}
      <div class="flex justify-center">
        <Spinner />
      </div>
    {:else}
      <div>
        {#if loginError}
          <span
            class="block p-4 bg-yellow-200 rounded font-semibold mb-4"
            in:fade>{@html loginError}</span
          >
        {/if}
        {#if headerMessage}
          <div
            class="text-sm p-3 text-gray-100 font-semibold ring-2 ring-primored rounded mb-2"
          >
            {headerMessage}
          </div>
        {/if}
        <form
          on:submit|preventDefault
          class="space-y-2 mb-4"
          xyz="delay-10"
          class:xyz-in={mounted}
        >
          <div class="flex flex-col">
            <div class="rounded shadow-sm bg-white -space-y-px">
              <div>
                <label for="email-address" class="sr-only">Email address</label>
                <input
                  id="email-address"
                  bind:value={email}
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  class="bg-transparent appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t focus:outline-none focus:ring-primored focus:border-primored focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label for="password" class="sr-only">Password</label>
                <input
                  id="password"
                  bind:value={password}
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required
                  class="bg-transparent appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b focus:outline-none focus:ring-primored focus:border-primored focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>
          </div>

          <div class="">
            {#key signInWithMagicLink}
              <button
                disabled={disabled || loadingEmail}
                in:fade
                on:click={signingUp ? signUp : signIn}
                type="submit"
                class="primary-button"
              >
                {#if loadingEmail}
                  <Spinner variants="mr-2 h-5 w-5" />
                {:else}
                  <span class="inset-y-0 flex items-center pr-2">
                    <svg
                      class="h-5 w-5 text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      ><path
                        d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                      /><path
                        d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                      /></svg
                    >
                  </span>
                {/if}
                {#if signingUp}
                  <span class="font-semibold">Sign up with Email</span>
                {:else if signInWithMagicLink}
                  <span class="font-semibold">Sign in with magic link</span>
                {:else}
                  <span class="font-semibold">Sign in</span>
                {/if}
              </button>
            {/key}
          </div>
          <div
            class="text-xs font-medium text-gray-100 flex justify-between flex-wrap transition-colors duration-100"
          >
            {#if !signingUp}
              <button
                type="button"
                on:click={resetPassword}
                class="hover:text-primored"
              >
                Forgot your password?
              </button>
            {/if}
            <button
              on:click={() => {
                signingUp = !signingUp
                loginError = ``
              }}
              type="button"
              class="underline hover:text-primored ml-auto"
            >
              {signingUp ? 'Sign in' : 'Sign up'}
            </button>
          </div>
        </form>
        <div class="my-4">
          <hr class="border-gray-800" />
        </div>
        <button
          id="signin-github"
          on:click={signUpWithProvider}
          class="primary-button"
          type="button"
        >
          {#if loadingProvider}
            <Spinner />
          {:else}
            <svg
              class="mr-2 w-5"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              width="24px"
              height="24px"
              ><path
                d="M44,24c0,8.96-5.88,16.54-14,19.08V38c0-1.71-0.72-3.24-1.86-4.34c5.24-0.95,7.86-4,7.86-9.66c0-2.45-0.5-4.39-1.48-5.9 c0.44-1.71,0.7-4.14-0.52-6.1c-2.36,0-4.01,1.39-4.98,2.53C27.57,14.18,25.9,14,24,14c-1.8,0-3.46,0.2-4.94,0.61 C18.1,13.46,16.42,12,14,12c-1.42,2.28-0.84,4.74-0.3,6.12C12.62,19.63,12,21.57,12,24c0,5.66,2.62,8.71,7.86,9.66 c-0.67,0.65-1.19,1.44-1.51,2.34H16c-1.44,0-2-0.64-2.77-1.68c-0.77-1.04-1.6-1.74-2.59-2.03c-0.53-0.06-0.89,0.37-0.42,0.75 c1.57,1.13,1.68,2.98,2.31,4.19C13.1,38.32,14.28,39,15.61,39H18v4.08C9.88,40.54,4,32.96,4,24C4,12.95,12.95,4,24,4 S44,12.95,44,24z"
              /></svg
            >
            <span>{signingUp ? 'Sign up' : 'Sign in'} with Github</span>
          {/if}
        </button>
        <!-- {#if signingUp}
        <label class="mt-2 text-gray-100 inline-flex items-center mb-2">
          <input bind:checked={userAvailable} type="checkbox" class="form-checkbox h-3 w-3 focus:ring-primored focus:text-primored">
          <span class="ml-2 text-sm">Contact me about my usage</span>
        </label>
      {/if} -->
      </div>
    {/if}
  </main>
{/key}

<style>
  main {
    @apply p-6 bg-gray-900 rounded-md space-y-4 border-4 border-primored;
  }
  .primary-button {
    @apply group bg-gray-800 relative w-full flex items-center justify-center py-2 px-4 border border-transparent text-sm font-semibold rounded text-white hover:text-white hover:bg-primored focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primored transition-colors duration-100;
  }
  button[disabled] {
    @apply opacity-75 pointer-events-none;
  }
</style>
