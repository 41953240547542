<script>
  import { fade } from 'svelte/transition'
  import axios from 'axios'
  import JSZip from 'jszip'
  import { saveAs } from 'file-saver'
  import { PrimaryButton, Button } from '../../@components/buttons'
  import { site, modal } from '@primo-app/primo'
  import { buildForGithub, makeValidUrl } from '../../utils'
  import { buildSite, createRepo } from '../../Github.svelte'
  import { repo, currentSite, user } from '../../stores'
  import { path } from '../../stores/misc'
  import { repos, sites } from '../../supabase/db'

  async function pushToRepo() {
    feedback = `Pushing site to Github`
    loading = true
    // const fullRepoName = `${$repo.owner}/${$repo.name}`
    const success = await buildSiteTree($site, $path)
    if (success) {
      feedback = 'Good to go!'
      progress = 100
      setTimeout(() => {
        modal.hide()
      }, 2000)
    } else {
      feedback = 'Something went wrong :('
    }
  }

  async function buildSiteTree(siteData, siteName) {
    // NEXT: pass correct site name 'mateomorris/test-1' as siteName
    const files = await buildForGithub(siteData, siteName)
    return await buildSite(
      {
        site: $path,
        files,
        message: commitMessage,
      },
      (ratio) => {
        progress = ratio * 100
      }
    ).catch((e) => {
      alert(`The site couldn't be saved for some reason. Sorry about that.`)
      console.error(e)
    })
  }

  let repoName = ''
  $: repoName.replace(' ', '-')

  let feedback = ''
  let loading = false
  let progress = 0

  let commitMessage = ''

  // if no repo exists for this site
  // If logged into Github
  // Option to create repository
  // If not logged into Github
  // Sign in w/ Github

  // get repo from db before this
  let currentMessage = $user.token
    ? `Your website will get built out to a Github repository. You can publish it from there.`
    : `Download your website or connect your Github account to save your site to a repository`
  async function startCreatingRepo() {
    loading = true
    const sitePath = $path.split('/')[1]
    const repoName = `primo-` + makeValidUrl(sitePath)
    const repoData = await createRepo({ name: repoName })
    console.log({ repoData })
    loading = false
    if (repoData) {
      const { html_url, owner, name, private: isPrivate } = repoData
      // save repo to db
      const repoRow = await repos.create({
        owner: owner.login,
        name,
        url: html_url,
        isPublic: !isPrivate,
        provider: 1,
      })
      // // attach new repo row to website
      sites.update($currentSite, {
        repo: repoRow.id,
      })
      $repo = repoRow
      commitMessage = `Repository created at ${$repo.url}, building and pushing site`
      pushToRepo()
      // Change screen to 'Your website has been built to {url}, this is where changes will be built to in the future'
    } else {
      alert(
        `Could not create a repository. Ensure you don't already have a repo named ${repoName}`
      )
    }
  }

  async function downloadSite() {
    loading = true
    const zip = new JSZip()
    const files = await buildForGithub($site, $path)
    files.forEach((file) => {
      zip.file(
        file.path,
        typeof file.content === 'string'
          ? file.content
          : 'h1 { font-size: 4rem; }'
      )
    })
    const toDownload = await zip.generateAsync({ type: 'blob' })
    saveAs(toDownload, `${$path}.zip`)
    modal.hide()
  }

  function connectRepo() {}
</script>

{#if !$repo}
  <div class="text-gray-100 p-8 space-y-4">
    <div class="">
      {currentMessage}
    </div>
    {#if $user.token}
      <PrimaryButton
        {loading}
        on:click={startCreatingRepo}
        label="Create Repository"
      />
    {:else}
      <PrimaryButton {loading} on:click={downloadSite}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4 mr-1"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
        <span>Download site bundle</span>
      </PrimaryButton>
      <hr class="border-gray-800" />
      <PrimaryButton {loading} on:click={connectRepo}>
        <i class="fab fa-github mr-1" />
        <span>Connect Repository</span>
      </PrimaryButton>
    {/if}
  </div>
{:else if !loading}
  <form on:submit|preventDefault={pushToRepo} in:fade={{ duration: 200 }}>
    <label class="flex flex-col items-start mb-4">
      <p class="text-base mb-1 text-gray-100 font-semibold">What changed?</p>
      <p class="text-gray-200 mb-4 text-sm">
        This lets other collaborators (and future you) know what changes you
        made to the website. The more descriptive you can be, the better.
      </p>
      <div class="bg-gray-900 text-gray-200 font-semibold p-4 text-sm mb-2">
        <svg
          class="inline-block w-4 h-4"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          ><path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"
          /></svg
        >
        to publish your primo site to the world wide web, you'll need to
        <a
          class="underline"
          target="blank"
          href="https://primo.gitbook.io/usage/basic-usage/deploy-site"
          >deploy it</a
        >. Don't worry - it's easy and usually free.
      </div>
      <input
        placeholder="Added an 'About us' page"
        class="w-full bg-gray-800 text-gray-100"
        bind:value={commitMessage}
        autofocus
        type="text"
        maxlength="50"
      />
    </label>
    <div class="flex justify-end mt-4">
      <PrimaryButton type="submit">Push changes</PrimaryButton>
    </div>
  </form>
{:else}
  {#if feedback}
    <div class="flex justify-center w-full">
      <p class="text-gray-200 font-semibold mb-4 text-xl">{feedback}</p>
    </div>
  {/if}
  <div class="w-full">
    <div class="w-full bg-gray-300 mt-2">
      <div
        class="bg-primored rounded text-xs leading-none py-2 text-center text-white duration-200"
        style="transition: width;width: {progress}%"
      />
    </div>
  </div>
{/if}

<style>
  textarea {
    @apply text-base bg-gray-800 text-gray-200 font-medium rounded-sm py-2 px-3 flex-1;
  }
</style>
