<script>
  import {createEventDispatcher} from 'svelte'
  const dispatch = createEventDispatcher()

  export let label
  export let value
  export let prefix = null
  export let suffix = null
  export let saveable = false
  export let variants = ''
</script>

<!-- svelte-ignore a11y-label-has-associated-control -->
<label class="block {variants}">
  <span class="text-gray-100 text-sm mb-1 block">{label}</span>
  <div class="flex items-center justify-center rounded overflow-hidden bg-gray-800">
    {#if prefix}
      <span class="inline-flex items-center px-3 rounded-l-md text-sm border-r border-gray-700 truncate">
        {prefix}
      </span>
    {/if}
    <input 
      {...$$restProps} 
      bind:value 
      on:input
      on:change
      on:focus
      type="text" 
      class="block w-full bg-transparent focus:outline-none text-gray-100"
    >
    <slot></slot>
  </div>
</label>

<style>
  input {
    @apply border-0;
  }
  input:focus {
    @apply outline-none;
  }
</style>