<script>
  import axios from 'axios'
  import { createEventDispatcher } from 'svelte'
  const dispatch = createEventDispatcher()

  import {saveFileToRepo} from '../../Github.svelte'
  import {Spinner} from '../../@components/misc'
  import {repo} from '../../stores'

  export let field = {
    value: {
      alt: '',
      url: field.value,
      size: null,
    }
  }

  if (typeof field.value === 'string' || !field.value) {
    field.value = {
      alt: '',
      url: field.value,
      size: null,
    }
  }

  function encodeImageFileAsURL(e) {
    var filesSelected = imageUploader.files;
    if (filesSelected.length > 0) {
      uploading = true
      const file = filesSelected[0]
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = async function(fileLoadedEvent) {
        const dataUrl = fileLoadedEvent.target.result
        imagePreview = dataUrl
        
        const imageData = dataUrl.replace(/data:image\/(png|jpg|jpeg);base64,/,'')

        const data = await saveFileToRepo({
          file: file.name,
          content: imageData,
          message: 'upload image'
        })

        if (data) {
          const {download_url:url, size} = data.content
          field.value = {
            ...field.value,
            url,
            size: Math.round(size / 1000)
          }
          dispatch('input')
        } else {
          alert('Could not upload image. Ensure it is a png or jpg and another image with the same name does not already exist in the repository.')
        }

        uploading = false

      }
    }
  }

  let imagePreview = field.value.url
  let uploading = false
  let imageUploader
  
</script>

<div class="flex flex-col">
  <span class="text-xs font-semibold">{field.label}</span>
  <div class="flex flex-col md:flex-row">
    <div class="image-preview" class:uploading role="img" style="background-image:url('{imagePreview}')" >
      {#if uploading}
        <Spinner />
      {/if}
      {#if field.value.size}
        <span class="bg-gray-900 text-gray-500 absolute top-0 right-0 p-1 text-xs font-semibold" style="border-bottom-left-radius: 0.25rem;">
          {field.value.size}KB
        </span>
      {/if}
    </div>
    <div class="flex flex-col w-full">
      <div class="image-upload hover:bg-gray-200" class:disabled={!$repo} title={!$repo ? 'Connect a Github repository to save images' : ''}>
        <label for="image-{field.id}" class="flex flex-col text-gray-200 bg-gray-900 font-semibold text-center items-center justify-center absolute top-0 bottom-0 left-0 right-0">
          <i class="fas fa-file-upload"></i>
          <span>Upload an image</span>
        </label>
        <input 
          on:change={encodeImageFileAsURL}
          class="invisible" 
          id="image-{field.id}" 
          bind:this={imageUploader}
          type="file" 
          accept="image/*" />
      </div>
      <label class="p-2 flex text-sm bg-gray-900 border-t border-gray-800">
        <span class="px-2">URL</span>
        <input 
          on:input={(e) => {
            const {value} = e.target
            imagePreview = value
            field.value = {
              ...field.value,
              url: value,
              size: null
            }
            dispatch('input')
          }}
          value={field.value.url}
          type="url" 
          class="flex-1 pl-2 outline-none"
        />
      </label>
    </div>
  </div>
  <div class="pt-3">
    <label class="w-full flex text-sm">
      <span class="font-semibold mr-2">Alt text</span> 
      <input type="text" class="flex-1 outline-none" bind:value={field.value.alt}>
    </label>
  </div>
  <!-- <label class="flex items-center flex-1">
    <span class="font-semibold text-sm mr-2">Image URL</span>
    <input bind:value={field.value} on:input={() => dispatch('input', field.value)} class="bg-gray-100 p-2 flex-1" type="url" placeholder="https://images.pexels.com/photos/104827/cat-pet-animal-domestic-104827.jpeg">
  </label> -->
</div>
<slot></slot>

<style>
  .image-upload.disabled {
    opacity: 0.5;
    cursor: default;

    input, label {
      pointer-events: none;
    }
  }
  input {
    @apply bg-gray-800;
  }
  .image-preview {
    @apply relative w-full md:w-48 mr-2 flex justify-center items-center transition-colors duration-200 bg-cover bg-center bg-no-repeat;

    &.uploading {
      @apply border-primored;
    }
  }
  .image-upload {
    @apply flex-1 p-4 cursor-pointer relative w-full flex items-center justify-center text-gray-200 transition-colors duration-100;
  }
</style>