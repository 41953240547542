<script>
  import { router } from 'tinro'
  import { sites, user } from '../../stores'
  import SiteThumbnail from '../../@components/SiteThumbnail.svelte'
  import Spinner from '../../@components/misc/Spinner.svelte'

  $: if ($user.signedIn) {
    sites.fetch()
  }

  function navigateToSite(site) {
    router.goto(
      `/${site.owner === $user.uid ? $user.username : site.owner}/${site.url}`
    )
  }
</script>

<ul class="space-y-3">
  {#each $sites as site}
    <li class="site-item rounded">
      <button
        on:click={() => navigateToSite(site)}
        class="flex flex-col bg-primored hover:opacity-75 transition-opacity duration-100 h-full w-full"
      >
        <div class="thumbnail">
          <SiteThumbnail {site} variants="row-span-3 h-32" />
        </div>
        <div class="card-footer bg-primored z-10">
          <div class="h-full flex flex-col justify-center text-xs text-left">
            <h2 class="text-gray-100 font-semibold">{site.name}</h2>
            <h3 class="text-gray-300">/{site.url}</h3>
          </div>
          <div>
            <svg
              class="text-white w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              ><path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              /></svg
            >
          </div>
        </div>
      </button>
    </li>
  {:else}
    <div class="flex justify-center">
      <Spinner />
    </div>
  {/each}
</ul>

<style>
  .card-footer {
    @apply text-xs px-3 py-2 transition-colors duration-100 flex justify-between items-center w-full;
  }
  .thumbnail {
    @apply h-full w-full;
  }
  ul {
    @apply mb-4 list-none;
  }
  .site-item {
    @apply shadow-lg relative overflow-hidden;
    &.active {
      outline: 5px solid rgb(30, 30, 30);
    }
  }
</style>
