<script lang="ts">
  import { users } from '../supabase/db'
  export let site
  console.log(site)

  const collaborators = Promise.all(
    site.collaborator_data.map(async (c) => {
      const user = await users.get(c.uid)
      return {
        ...user,
        role: c.role === 'CONTENT' ? 'Content Editor' : 'Developer',
      }
    })
  )

  $: console.log({ collaborators })
</script>

<div class="text-gray-100 bg-gray-900 p-8 ring-2 ring-primored rounded">
  <h1 class="text-xl font-bold mb-2">Site Collaborators</h1>
  <table style="width:100%">
    <tr>
      <th>Username</th>
      <th>Role</th>
    </tr>
    {#await collaborators then c}
      {#each c as user}
        <tr>
          <td>{user.username}</td>
          <td>{user.role}</td>
        </tr>
      {/each}
    {/await}
  </table>
</div>

<style>
  table {
    text-align: left;
  }
</style>
