<script>
  import { slide } from 'svelte/transition'
  import { createEventDispatcher } from 'svelte'
  const dispatch = createEventDispatcher() 
  import {user} from '../../stores'
  import {User} from '../../constructs'

  let deletingAccount = false
  let typedConfirmation = '';
  $: canDeleteAccount = (typedConfirmation === 'I understand I am irreversibly deleting my account and websites')

  function beginAccountDeletion() {
    deletingAccount = true;
  }
  function undoAccountDeletion() {
    deletingAccount = false;
    typedConfirmation = '';
  }
  
  async function finalizeAccountDeletion() {
    if (passwordCorrect) {
      // let deletedRecord = await deleteUserRecord() TODO
      let deltedRecord = true
      // if (deletedRecord) await deleteUserAccount() TODO
      dispatch('delete')
      user.set( User() )
    } else {
      alert('Could not delete your account. Make sure your password is correct.')
    }
  }

</script>

{#if deletingAccount}
  <article class="p-4 ring-2 ring-primored" transition:slide>
    <div class="message-body">
      <strong class="mb-2">Deleting your account will also delete all of your websites and data. This cannot be reversed.</strong>
      <button class="block underline" on:click={finalizeAccountDeletion} disabled={!canDeleteAccount}>I understand, delete my account</button>
    </div>
  </article>
{:else}
  <button class="underline" on:click={beginAccountDeletion}>Delete account</button>
{/if}

<style>
  .button {
    @apply px-2 rounded;
  }

  input {
    @apply bg-gray-100;
  }
</style>