<script lang="ts">
  import LandingPage from './LandingPage.svelte'
  import Dashboard from './screens/Dashboard.svelte'
  import Site from './screens/Site.svelte'
  import Try from './screens/Try.svelte'
  import { modal, user, sites } from './stores'
  import {
    fieldTypes,
    PrimoFieldTypes,
    dropdown,
    ComponentPreview,
    modal as primoModal,
    registerProcessors,
    site,
  } from '@primo-app/primo'
  import { watchForAutoLogin, signOut } from './supabase/auth'
  import { users, createAccount } from './supabase/db'
  import ImageField from './extensions/FieldTypes/ImageField.svelte'
  import SiteButtons from './extensions/Dropdown/SiteButtons.svelte'
  import Build from './extensions/Build/Build.svelte'
  import Authentication from './@modal/Authentication.svelte'
  import FooterText from './extensions/FooterText.svelte'
  import { html, css } from './extensions/processors'
  import Modal from './@modal'
  import * as modals from './@modal'

  import { Route, router } from 'tinro'

  registerProcessors({ html, css })

  // Register Modals
  primoModal.register([
    {
      id: 'BUILD',
      component: Build,
      componentProps: {
        siteName: 'Website', // TODO - change
      },
      options: {
        route: 'build',
        width: 'md',
        header: {
          title: 'Build to Github',
          icon: 'fab fa-github',
        },
      },
    },
    {
      id: 'AUTH',
      component: Authentication,
      componentProps: {
        onSignIn: () => {
          $user.signedIn = true
          primoModal.hide()
        },
      },
      options: {
        route: 'auth',
        width: 'xs',
        disableClose: true,
        disabledBgClose: true,
        noPadding: true,
        footer: FooterText,
      },
    },
  ])

  // Register Field Types
  fieldTypes.register([
    {
      id: 'image',
      label: 'Image',
      component: ImageField,
    },
    ...PrimoFieldTypes,
  ])

  // Register Dropdown buttons
  dropdown.register([
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'fas fa-arrow-left',
      variants:
        'text-gray-100 bg-black ring ring-primored hover:bg-primored transition-colors duration-100',
      href: '/dashboard',
    },
    {
      component: SiteButtons,
    },
    {
      id: 'docs',
      label: 'Documentation',
      icon: 'fas fa-book',
      href: 'https://primo.gitbook.io/usage/',
    },
    {
      id: 'discuss',
      label: 'Discuss',
      icon: 'fab fa-discord',
      href: 'https://discord.gg/jpZwmJ',
    },
    {
      id: 'report',
      label: 'Report a Bug',
      icon: 'fab fa-github',
      href: 'https://github.com/primo-af/primo/issues',
    },
    {
      id: 'sign-out',
      label: 'Sign Out',
      icon: 'fas fa-sign-out-alt',
      onClick: async () => {
        await signOut()
        window.location.reload()
      },
    },
  ])

  $: activeModal = getActiveModal($modal.id)
  function getActiveModal(modal) {
    return (
      {
        AUTH: modals.Authentication,
        INVITATION: modals.Invitation,
        USER_SETTINGS: modals.UserSettings,
        SITE_CREATION: modals.SiteCreation,
        COLLABORATORS: modals.Collaborators,
      }[modal] || null
    )
  }

  watchForAutoLogin(async (event, session) => {
    if (event === 'SIGNED_IN') {
      const { user: primaryData, provider_token } = session
      const { id, user_metadata } = primaryData
      const existingUser = await users.get(
        id,
        `
				username,
				email,
				tokens
			`
      )

      if (existingUser && provider_token) {
        await users.update(id, {
          tokens: { github: provider_token },
        })
      } else if (!existingUser) {
        await users.create({
          id,
          username: id,
          email: primaryData.email,
          tokens: provider_token ? { github: provider_token } : null,
        })
      }

      const { username, email, tokens } = existingUser || primaryData
      const token = provider_token || (tokens ? tokens.github : null)

      user.update((u) => ({
        ...u,
        uid: id,
        username: username || id,
        email,
        token,
        signedIn: true,
        avatar: user_metadata?.avatar_url,
      }))
    } else if (event === 'SIGNED_OUT') {
      user.reset()
      router.goto('/')
    } else if (event === 'PASSWORD_RECOVERY') {
      passwordResetToken = session.access_token
    } else {
      console.warn('NEW AUTH EVENT', event)
    }
  })

  function onSitePage(path) {
    return path.split('/').filter(Boolean).length >= 2
  }

  $: if ($user.signedIn) {
    sites.fetch()
  }

  let passwordResetToken
</script>

<Modal visible={!!activeModal}>
  <svelte:component this={activeModal} {...$modal.componentProps} />
</Modal>

{#if $router.path === '/dashboard'}
  <Dashboard />
{:else if $router.path.split('/')[1] === 'try'}
  <Try />
{:else if onSitePage($router.path)}
  <Site />
{:else if $router.path.split('/')[1] === 'preview'}
  <ComponentPreview />
{:else}
  <LandingPage {passwordResetToken} />
{/if}

<svelte:head>
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/@animxyz/core@0.4.0/dist/animxyz.min.css"
  />
</svelte:head>

<style global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  html,
  body {
    /* prevent two-finger swipe back */
    overscroll-behavior-x: none;
  }
</style>
