<script>
  import modal from '../stores/modal'
  import { fade } from 'svelte/transition'

  export let visible

  async function closeModal() {
    modal.hide()
  }

</script>

{#if visible}
  <div class="modal mousetrap" transition:fade={{ duration: 100 }}>
    <div class="modal-background" on:click={closeModal}></div>
    <div class="modal-card max-w-2xl">
      <div class="modal-card-body max-w-2xl">
        <slot></slot>
      </div>
    </div>
  </div>
{/if}

<style>

  .modal {
    @apply flex flex-col items-center justify-center overflow-hidden fixed z-40 bottom-0 left-0 right-0 top-0;
  }

  .modal-background {
    @apply bottom-0 left-0 right-0 top-0 absolute bg-black bg-opacity-95;
  }

  .modal-card {
    @apply flex flex-col w-full my-0 mx-auto relative rounded items-center;
    position: relative;
    /* max-width: calc(100vw - 2rem); */
    max-height: calc(100vh - 2rem);
  }

  .modal-card-foot, .modal-card-head {
    @apply flex items-center justify-start p-4 relative;
  }

  .modal-card-head {
    @apply text-gray-800 text-lg font-semibold py-2 px-4;
  }

  .modal-card-title {
    @apply text-lg;
  }

  .modal-card-title > .modal-title {
    @apply pl-1;
  }

  .modal-card-body {
    @apply flex-1 p-5 w-full overflow-y-scroll;
  }

  .modal-card-foot {
    justify-content: flex-end !important;
  }

  .modal {
    z-index: 999;
  }

  .fullscreen {
    width: calc(100vw - 3rem) !important;
    height: calc(100vh - 3rem) !important;
    position: absolute !important;
    left: 1.5rem !important;
    right: 1.5rem !important;
    top: 1.5rem !important;
    bottom: 1.5rem !important;
    max-height: initial !important;
  }

  .fullscreen .modal-card-body {
    padding-bottom: 0;
    padding-top: 0;
    @apply overflow-scroll;
  }

</style>