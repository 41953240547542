import _ from "lodash";
import axios from 'axios'
import { get } from 'svelte/store'
import ShortUniqueId from "short-unique-id";
import { css } from './extensions/processors'
import { processContent } from '@primo-app/primo/src/stores/helpers'
import { formatCode } from '@primo-app/primo/src/utilities'

import { user } from './stores'

const functionsServer = (endpoint) => window.location.hostname.includes('localhost') ? `http://localhost:9000/primo-d4041/us-central1/${endpoint}` : `https://us-central1-primo-d4041.cloudfunctions.net/${endpoint}`

export const ax = {
  async post(endpoint, params, onError = () => { }) {
    // console.log('post:', functionsServer(endpoint), params)
    try {
      let { data } = await axios.post(functionsServer(endpoint), params)
      return data
    } catch (e) {
      console.error(e)
      onError(e)
      return { error: e }
    }
  },
  async get(endpoint) {
    // console.log('get:', functionsServer(endpoint))
    try {
      return await axios.get(functionsServer(endpoint))
    } catch (e) {
      console.error(e)
      return false
    }
  }
}


export async function authorizeGithub(token) {
  const gitres = await ax.post('github', { token })
  return gitres
}


export async function transferSite(site) {
  return await ax.post('firestore/transfer-site', { site })
}


export function convertFieldsToData(fields) {
  let literalValueFields = fields
    .filter(f => f.type !== 'js')
    .map(f => ({
      key: f.key,
      value: f.type === 'number' ? parseInt(f.value) : f.value
    }))
    .reduce((obj, item) => (obj[item.key] = item.value, obj), {});

  return _.chain(parsedFields)
    .keyBy('key')
    .mapValues('value')
    .value();
}

export async function notify(params, appName = 'firebase') {
  ax.post('primo/notify', {
    appName,
    params
  })
}

// Lets us debounce from reactive statements
export function createDebouncer(time) {
  return _.debounce(val => {
    const [fn, arg] = val
    fn(arg)
  }, time)
}

export function createInstance(symbol) {
  const instanceID = getUniqueId()
  const instanceFinalCSS = symbol.value.css.replace(RegExp(`${symbol.id}`, 'g'), `${instanceID}`)
  return {
    type: 'component',
    id: instanceID,
    symbolID: symbol.id,
    value: {
      ...symbol.value,
      final: {
        ...symbol.value,
        css: instanceFinalCSS
      }
    }
  }
}


export function getUniqueId() {
  return new ShortUniqueId().randomUUID(5).toLowerCase();
}

export function getComponentPreviewCode(component, parentStyles) {
  return `<div id="component-${component.id}">${component.value.final.html}</div><style>${parentStyles}${component.value.final.css}</style><script type="module">${component.value.final.js}</script>`
}

export async function checkIfUserHasSubdomain(email, subdomain) {
  if (email && subdomain) {
    const res = await ax.post('firestore/subdomain-has-user', { email, subdomain })
    return res
  } else {
    return false
  }
}

export async function checkIfSiteHasUser(uid, repo) {
  if (uid && repo) {
    const res = await ax.post('firestore/site-has-user', { uid, repo })
    return res
  } else {
    return false
  }
}

export async function sendSiteInvitation({ repo, email, role }) {
  const res = await ax.post('primo/send-invite', { repo, email, role })
  return res
}

export async function processStyles(css, options = {}) {
  const result = await ax.post('postcss', { css, options })
  console.log({ result })
  if (result.error) {
    console.error(result.error)
    return '';
  } else {
    return result;
  }
}

async function buildPageHTML({ page, site, isChild = false }) {
  let html = ''
  const processed = await processContent(page, site)
  for (let block of processed) {
    if (block.type === 'component') {
      html += `
      <div class="block" id="block-${block.id}">
        <div class="primo-component" id="component-${block.id}">
          <div>${block.html}</div>
          ${block.js ? `<script type="module">${block.js}</script>` : ``}
        </div>
        ${block.css ? `<style type="text/css">${block.css}</style>` : ``}
      </div>
      `
    } else if (block.type === 'content') {
      html += `
        <div class="block" id="block-${block.id}">
          <div class="primo-copy" id="copy-${block.id}">
            ${block.value.html}
          </div>
        </div>
      `
    }
  }

  // change image links to local instead of github
  const imgRegex = /src=(?:['"])(?:[\S]+)(assets\/.*?)(?:['"])/g
  if (page.id === 'index') {
    html = html.replace(imgRegex, `src='./$1'`)
  } else if (isChild) {
    html = html.replace(imgRegex, `src='../../$1'`)
  } else {
    html = html.replace(imgRegex, `src='../$1'`)
  }

  function buildStyleTag() {
    let href = ``
    if (page.id === 'index') {
      href = './styles.css'
    } else if (isChild) {
      href = '../../styles.css'
    } else {
      href = '../styles.css'
    }
    return `<link rel="stylesheet" type="text/css" href="${href}" />`
  }

  return `
    <!doctype html>
    <html lang="en">
      <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        ${buildStyleTag()}
        ${page.styles.raw ? `<style type="text/css">${page.styles.final}</style>` : ``}
        ${site.wrapper.head.final}
        ${page.wrapper.head.final}
      </head>
      <body data-instant-intensity="all" class="primo-page">   
        ${html}
        ${site.wrapper.below.final}
        ${page.wrapper.below.final}
      </body>
    </html>
  `

}


export function getTailwindConfigObj(pageTW, siteTW) {
  const combined = getCombinedTailwindConfig(pageTW, siteTW)
  let asObj = {}
  try {
    asObj = new Function(`return ${combined}`)()
  } catch (e) {
    console.warn(e)
  }
  return asObj
}

function getCombinedTailwindConfig(pageTW, siteTW, override = {}) {
  let siteTailwindObject
  let pageTailwindObject
  try {
    siteTailwindObject = new Function(`const require = (id) => id; return ${siteTW}`)() // convert string object to literal object
    pageTailwindObject = new Function(`const require = (id) => id; return ${pageTW}`)() // overwrite require so it doesn't throw an error when evaluated
    const combined = _.merge(siteTailwindObject, pageTailwindObject)
    const overridden = { ...combined, ...override }
    const asString = JSON.stringify(overridden)
    let withPlugins = asString.replace(`"@tailwindcss/ui"`, `require('@tailwindcss/ui')`) // replace require statements
    withPlugins = withPlugins.replace(`"@tailwindcss/typography"`, `require('@tailwindcss/typography')`)
    return withPlugins
  } catch (e) {
    console.error(e)
    return {}
  }
}

export async function buildForGithub(site, siteName) {

  const primoPage = `
    <!doctype html>
    <html lang="en">
      <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
      </head>

      <body class="primo-page">   
        <iframe allow="clipboard-read; clipboard-write self https://its.primo.af" border="0" src="https://its.primo.af/${siteName}" style="height:100vh;width:100vw;position:absolute;top:0;left:0;border:0;"></iframe>
      </body>
    </html>
  `

  const pages = await Promise.all(
    [
      ...site.pages.map(page => buildPageTree({ page, site })),
      [
        {
          path: `primo/index.html`,
          content: primoPage
        }
      ]
    ]
  )

  return buildSiteTree(pages, site)

  async function buildPageTree({ page, site, isChild = false }) {
    const { content, id } = page
    const HTML = await buildPageHTML({ page, site, isChild })
    const formattedHTML = await formatCode(HTML, 'html')

    return await Promise.all([
      {
        path: `${id === 'index' ? `index.html` : `${id}/index.html`}`,
        content: formattedHTML
      },
      ...page.pages ? page.pages.map(subpage => buildPageTree({ page: subpage, site, isChild: true })) : [],
    ])
  }

  async function buildSiteTree(pages, site) {
    const json = JSON.stringify(site)

    const siteHTML = getSiteHTML(site)

    const styles = await processStyles(`
      @tailwind base;
      @tailwind components;
      @tailwind utilities;
      ${site.styles.raw}
    `, {
      tailwind: site.styles.tailwind,
      html: siteHTML
    })

    console.log({ styles })

    return [
      ..._.flattenDeep(pages),
      {
        path: `styles.css`,
        content: styles
      },
      {
        path: `primo.json`,
        content: json
      },
      {
        path: 'README.md',
        content: `# Built with [primo](https://primo.af)`
      }
    ]

    function getSiteHTML(site) {
      const symbolHTML = site.symbols.map(symbol => symbol.value.html).join(' ')
      const componentHTML = _.flattenDeep(
        site.pages.map(
          page => page.content
            .filter(block => block.type === 'component' && !block.symbolID)
            .map(block => block.value.html)
        )
      ).join(' ')
      return symbolHTML + componentHTML
    }
  }
}


// make a url string valid
export const makeValidUrl = (str = '') => {
  if (str) {
    return str.replace(/\s+/g, '-').replace(/[^0-9a-z\-._]/ig, '').toLowerCase()
  } else {
    return ''
  }
}

export async function fixSiteData(data) {
  let fixedData = _.cloneDeep(data)
  fixedData.pages = fixedData.pages.map(page => {
    if (page.content[0]?.columns) {
      page.content = formatContentFromColumns(page.content)
    }
    if (page.content[0]?.value?.raw) {
      page.content = formatContentFromRaw(page.content)
    }

    return page
  })

  if (fixedData.symbols[0]?.value?.raw) {
    fixedData.symbols = formatSymbolsFromRaw(fixedData.symbols)
  }

  return fixedData

  function formatContentFromRaw(content) {
    return content.map(block => {
      if (block.type === 'component') {
        return {
          id: block.id,
          symbolID: block.symbolID,
          type: block.type,
          value: block.symbolID ? { fields: block.value.raw.fields } : block.value.raw
        }
      } else return block
    })
  }

  function formatContentFromColumns(content) {
    let newcontent = []
    for (let section of content) {
      for (let column of section.columns) {
        for (let block of column.rows) {
          newcontent = [...newcontent, block]
        }
      }
    }
    return newcontent
  }

  function formatSymbolsFromRaw(symbols) {
    return symbols.map(symbol => ({
      ...symbol,
      value: symbol.value.raw
    }))
  }
}

