import {writable} from 'svelte/store'
import {createSite} from '@primo-app/primo'

const store = writable(createSite());

export default {
  reset: () => {
    store.set(createSite())
  },
  set: store.set,
  subscribe: store.subscribe
}