<script>
  import {delay} from 'lodash'
  import { user } from '../../stores';
  import {users} from '../../supabase/db'
  import {checkIfUsernameAvailable} from '../../supabase/middleware'
  import firebase from '../../firebase/index.js'
  const functions = firebase.functions()

  if (window.location.hostname === 'localhost') {
    functions.useFunctionsEmulator('http://localhost:9000')
  }

  import Button from '../../@components/buttons/Button.svelte'
  import TextField from '../../@components/inputs/TextField.svelte'
  import {makeValidUrl} from '../../utils'
  import DangerZone from './DangerZone.svelte'

  let username = $user.username
  $: validateUsername(username)
  function validateUsername(typedUsername) {
    username = makeValidUrl(typedUsername)
  }

  $: usernameChanged = username !== $user.username
  
  $: usernameTooShort = username.length <= 3
  let usernameTaken = true
  $: usernameChanged && checkUsernameAvailability(username).then(available => {
    usernameTaken = !available
  })

  $: canSave = usernameChanged && !(usernameTooShort || usernameTaken)

  let loading = false
  async function checkUsernameAvailability(username) {
    loading = true
    const {data:available} = await checkIfUsernameAvailable(username)
    loading = false
    return available
  }

  function saveUsername() {
    users.update($user.uid, { username })
    user.update(u => ({
      ...u,
      username
    }))
    usernameChanged = false
  }

</script>

<div class="text-gray-100 bg-gray-900 p-8 ring-2 ring-primored rounded">
  <h1 class="text-xl font-bold mb-2">Profile</h1>
  <div class="space-y-2">
    <TextField label="Username" bind:value={username} />
    {#if usernameChanged}
      {#if usernameTooShort}
        <p>Your username should be more than three characters long.</p>
      {:else if usernameTaken}
        <p><strong>{username}</strong> username is taken</p>
      {:else}
        <p>Your websites will move to <strong>/{username}</strong></p>
      {/if}
    {:else}
      <p>Your websites live under <strong>/{username}</strong></p>
    {/if}
    <div class="flex justify-end">
      <Button 
        on:click={saveUsername} 
        disabled={!canSave || loading} 
        variants="rounded inline-block"
      >Save</Button>
    </div>
  </div>
  <hr class="border-gray-800 my-6">
  
  <DangerZone />

</div>