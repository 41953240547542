<script>
  import Spinner from '../misc/Spinner.svelte'

  export let label = ''
  export let id = null
  export let variants = ''
  export let type = 'button'
  export let disabled = false
  export let loading = false
</script>

{#if type === 'input'}
  <label for="primo-json" class="text-gray-100 bg-black hover:bg-primored ring-2 ring-primored {variants}">
    {#if loading}
      <Spinner />
    {:else}
      <slot>{label}</slot>
    {/if}
    <input on:change type="file" id="primo-json" accept=".json">
  </label>
{:else}
  <button on:click {id} class="text-gray-100 bg-black hover:bg-primored ring-2 ring-primored {variants}" {disabled} {...$$restProps} {type}>
    {#if loading}
      <Spinner />
    {:else}
      <slot>{label}</slot>
    {/if}
  </button>
{/if}

<style>
  button, label {
    @apply cursor-pointer flex justify-center items-center w-full py-2 rounded font-medium transition-all duration-100;

    &[disabled] {
      @apply opacity-50 pointer-events-none;
    }
  }

  label input {
    display: none;
  }
</style>