<script>
  import {signOut} from '../../supabase/auth'
  import Logo from '../../@svg/Logo.svelte'

  import { user, modal } from '../../stores'

  function openUserSettings() {
    modal.show('USER_SETTINGS')
  }

  function signOutUser() {
    signOut()
  }

  export let variants = ''

</script>

<nav role="navigation" aria-label="main navigation" class="px-4 text-gray-300 {variants}">
  <a href="/" class="w-32">
    <Logo />
  </a>
  <div class="flex"> 
    {#if $user.email}
      <button class="text-xs mr-4 hover:text-gray-500 transition-colors duration-100 focus:opacity-75 focus:outline-none" on:click={openUserSettings}>
        <span class="sr-only">User</span>
        {#if $user.avatar}
          <img alt="user" src={$user.avatar} class="w-6 h-6 rounded-full ring-2 ring-gray-800 hover:ring-primored object-cover">
        {:else}
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>
        {/if}
      </button>
      <button on:click={signOutUser} class="text-xs font-semibold rounded bg-gray-800 text-gray-200 py-2 px-3 hover:bg-gray-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-primored transition-colors duration-100">Sign out</button>
    {/if}
  </div>
</nav>

<style>
  .auth-button {
    @apply ml-4 font-bold py-2 px-4 text-gray-100 bg-primored rounded-lg inline-block transition-colors duration-200;
    will-change: background-color;
  }
  .auth-button:hover {
    @apply bg-gray-100 text-gray-800;
    i {
      @apply text-gray-800;
    }
  } 


  nav {
    @apply flex justify-between items-center;
  }
  .logo {
    width: 8rem;
  }

</style>