<script>
  import axios from 'axios'
  import SiteThumbnail from '../@components/SiteThumbnail.svelte'
  import { Spinner } from '../@components/misc'
  import supabase from '../supabase/core'
  import { repos, sites as supabaseSites, users } from '../supabase/db'
  import {
    downloadSiteData,
    uploadSiteData,
    uploadPagePreview,
  } from '../supabase/storage'
  import { user, repo, sites, currentSite } from '../stores'
  import { makeValidUrl, fixSiteData } from '../utils'
  import { createRepo, fetchDataFromRepo } from '../Github.svelte'
  import TextField from '../@components/inputs/TextField.svelte'
  import PrimaryButton from '../@components/buttons/PrimaryButton.svelte'
  import { createSite } from '@primo-app/primo/src/const'
  import { buildPagePreview } from '@primo-app/primo/src/stores/helpers'

  export let onSuccess
  let loading
  let siteName = ``
  let siteID = ``
  let existingRepo = ``
  let siteIDFocused = false
  let message = ''
  $: siteURL = siteID
  $: canCreateSite = siteName && siteID

  let data

  async function createNewSite() {
    loading = true

    const site = await supabaseSites.create({
      name: siteName,
      owner: $user.uid,
      url: siteURL,
    })

    let preview
    if (data) {
      const homepage = data.pages.filter((p) => p.id === 'index')[0]
      preview = await buildPagePreview({ page: homepage, site: data })
    } else {
      data = createSite(siteName)
      preview = '<div></div>'
    }

    console.log({ data, preview })

    const [{ websites }] = await Promise.all([
      users.get($user.uid, `websites`),
      uploadPagePreview({
        path: `${$user.uid}/${site.id}/preview.html`,
        preview,
      }),
      uploadSiteData({ owner: $user.uid, id: site.id, data }),
    ])

    await users.update($user.uid, {
      websites: websites ? [...websites, site.id] : [site.id],
    })

    onSuccess(site.url)
    loading = false
  }

  function validateUrl() {
    siteID = makeValidUrl(siteIDFocused ? siteID : siteName)
  }

  let themes = []
  supabase
    .from('websites')
    .select('id, name, owner')
    .filter('istheme', 'eq', true)
    .then((res) => {
      themes = [
        {
          id: null,
          name: 'Blank',
          preview: '<div></div>',
        },
        ...res.data,
      ]
    })

  let selectedTheme = { id: null }
  async function selectTheme(theme) {
    selectedTheme = theme
    if (theme.id) {
      const res = await downloadSiteData({
        owner: '7ff42f0c-6a06-4ff4-b23e-695e11075350',
        id: theme.id,
      })
      const json = await res.data.text()
      const themeData = JSON.parse(json)
      data = {
        ...themeData,
        id: siteID,
        name: siteName,
      }
    } else {
      data = createSite(siteName)
    }
  }

  let duplicatedSitePreview
  function readJsonFile({ target }) {
    duplicatedSitePreview = ''
    var reader = new window.FileReader()
    reader.onload = async function ({ target }) {
      data = JSON.parse(target.result)
      const [homepage] = data.pages.filter((p) => p.id === 'index')
      duplicatedSitePreview = await buildPagePreview({
        page: homepage,
        site: data,
      })
    }
    reader.readAsText(target.files[0])
  }

  $: console.log({ themes })
</script>

<div class="text-gray-100 bg-gray-900 p-8 ring-2 ring-primored rounded">
  {#if !loading}
    <h1 class="text-xl font-bold mb-2">Create a site</h1>
    <form on:submit|preventDefault={createNewSite}>
      <div class="space-y-2 mb-6">
        <TextField
          autofocus={true}
          label="Site Name"
          on:input={validateUrl}
          bind:value={siteName}
        />
        <TextField
          label="Site URL"
          bind:value={siteID}
          on:input={validateUrl}
          on:focus={() => (siteIDFocused = true)}
        />
      </div>
      {#if !duplicatedSitePreview}
        <details class="mb-6" open>
          <summary class="focus:outline-none cursor-pointer text-sm"
            >Themes</summary
          >
          <div class="mt-2">
            <ul class="grid grid-cols-2 gap-4">
              {#each themes as theme}
                <div>
                  <SiteThumbnail
                    site={theme}
                    preview={theme.preview}
                    variants="aspect-w-16 aspect-h-12"
                  />
                  <button
                    class:bg-primored={theme.id === selectedTheme.id ||
                      (!theme.id && !selectedTheme)}
                    on:click={() => selectTheme(theme)}
                    type="button"
                    class="text-sm font-medium flex justify-between items-center w-full rounded-br rounded-bl bg-gray-800 hover:bg-primored transition-colors duration-200 py-1 px-2"
                  >
                    <h3>{theme.name}</h3>
                    {#if theme.id === selectedTheme.id}
                      <span class="py-1 px-3 ">selected</span>
                    {:else}
                      <span class="py-1 px-3 underline">use</span>
                    {/if}
                  </button>
                </div>
              {/each}
            </ul>
          </div>
        </details>
      {/if}
      <details id="advanced-options">
        <summary class="focus:outline-none cursor-pointer text-sm"
          >Advanced options</summary
        >
        <div class="mt-2">
          <div class="file">
            <PrimaryButton type="input" on:change={readJsonFile}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>Duplicate from primo.json</span>
            </PrimaryButton>
          </div>
          {#if duplicatedSitePreview}
            <SiteThumbnail
              preview={duplicatedSitePreview}
              variants="aspect-w-16 aspect-h-12"
            />
          {/if}
        </div>
      </details>
      <PrimaryButton
        type="submit"
        label={existingRepo ? 'Clone site from repo' : 'Create site'}
        disabled={!canCreateSite}
      />
    </form>
  {:else}
    <div class="flex space-x-4 items-center">
      <h1 class="text-xl text-gray-100 font-semibold">Creating {siteName}</h1>
      {#key message}
        <p>{message}</p>
      {/key}
      <Spinner />
    </div>
  {/if}
</div>

<style>
  #advanced-options {
    margin-bottom: 1.5rem;

    .file {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;

      input {
        display: none;
      }
    }
  }
</style>
