<script>
  const date = new Date();  
  const month = date.toLocaleString('default', { month: 'long' });

  export let variants= ''
</script>

<footer class="container px-4 flex-col items-center md:flex-row {variants}">
  <span class="disclaimer">
    <span class="primo text-left">primo</span> public beta
  </span>
  <div class="footer-links">
    <div>
      <!-- <a use:link href="/about">About</a> -->
      <!-- | <a use:link href="/pricing">Pricing</a> -->
      <a href="https://discord.gg/vzSFTS9">Community</a>
      | <a href="https://www.iubenda.com/terms-and-conditions/39882203" class="iubenda-nostyle no-brand iubenda-embed" title="Terms and Conditions ">Terms</a>
      | <a href="https://www.iubenda.com/privacy-policy/39882203" class="iubenda-nostyle no-brand iubenda-embed" title="Privacy Policy ">Privacy</a>
    </div>
    <span class="copyright primo">©</span>
  </div>
</footer>

<style>
  .on-dark {
    @apply text-gray-200;
    a {
      @apply text-gray-300 transition-colors duration-200;
      &:hover {
        @apply text-white;
      }
    }
  }

  .on-light {
    @apply text-gray-800;
    a {
      @apply text-gray-800 transition-colors duration-200;
      &:hover {
        @apply text-gray-700;
      }
    }
  }

  footer {
    @apply text-gray-300 flex flex-row flex-wrap justify-between w-full max-w-2xl;
  }

  a {
    @apply text-gray-300 transition-colors duration-200;
    &:hover {
      @apply text-white;
    }
  }

  .footer-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-links a {
    margin: 0 0.5rem;
  }

  .footer-links a:first-child {
    margin-left: 0;
  }

  .disclaimer {
    display: block;
  }

  .primo {
    font-weight: 800;
    @apply text-primored;
  }
</style>